<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <table-search :search.sync="search" />
            </v-col>
            <v-col>
                <table-buttons
                    :add-dialog.sync="addDialog"
                    :no-data-dialog.sync="noDataDialog"
                    :form-title="tableSchema.formTitle"
                    :claim-name="tableSchema.claimName"
                    :is-add-disabled="isAddDisabled"
                    @download="download()"
                    @addItem="addItem()">
                    <template v-slot:form="">
                        <component
                            :is="form"
                            v-if="!isEmpty(form)"
                            :key="formKey"
                            v-model="editedItem"
                            :schema="getSchema(tableSchema.claimName)"
                            :filter="tableFilter"
                            @cancel="addDialog = false"
                            @open="addDialog = true"
                            @input="onInput()"
                            @delete="onDelete" />
                        <app-form
                            v-else
                            :key="formKey"
                            v-model="editedItem"
                            :schema="getSchema(tableSchema.claimName)"
                            :filter="tableFilter"
                            @cancel="addDialog = false"
                            @open="addDialog = true"
                            @input="onInput()"
                            @delete="onDelete" />
                    </template>
                </table-buttons>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <table-layout
                    :get-slot-name="getSlotName"
                    :table-height="getTableHeight"
                    :items-per-page="getItemsPerPage"
                    :custom-sort="customSort"
                    :headers="filteredHeaders"
                    :items="computedItems"
                    :footer-props="footerProps"
                    :options.sync="options"
                    :item-total="itemTotal"
                    :claim-name="tableSchema.claimName"
                    :show-expand="showExpand"
                    :has-edit-page="hasEditPage"
                    :is-loading="isLoading"
                    :hide-default-footer="hideDefaultFooter"
                    @goToEditPage="goToEditPage()">
                    <template v-slot:tableCell="{ header, item }">
                        <table-cell
                            :header="header"
                            :item="item"
                            :download-file="downloadFile"
                            :go-to-table="goToTable"
                            :api-data="apiData"
                            :filter="tableFilter" />
                    </template>
                    <template v-slot:tableActions="{ item }">
                        <table-cell-actions
                            :item="item"
                            :claim-name="tableSchema.claimName"
                            :is-edit-disabled="isEditDisabled"
                            :has-edit-page="hasEditPage"
                            @editItem="editItem"
                            @goToEditPage="goToEditPage">
                            <template v-slot:cellButtons="{ }">
                                <slot name="cellButtons" :item="item" />
                            </template>
                        </table-cell-actions>
                    </template>
                </table-layout>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import tableMixin from "@/mixins/tableMixin";

export default {
    components: {
        tableLayout: () => import("@/components/TableLayout"),
        tableCell: () => import("@/components/TableCell"),
        tableCellActions: () => import("@/components/TableCellActions"),
        tableButtons: () => import("@/components/TableButtons"),
        tableSearch: () => import("@/components/TableSearch"),
        appForm: () => import("@/components/AppForm"),
    },
    mixins: [tableMixin],
};
</script>
